import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '@app/state/core.reducer';
import { coreQuery } from '@app/state/core.selectors';
import { Observable } from 'rxjs';

@Injectable()
export class CoreFacade {
  constructor(private store: Store<AppState>) {
  }

  getStoreByName(storeKey: string): Observable<any> {
    return this.store.pipe(select(coreQuery.getStore(storeKey)));
  }
}

export const coreFacades = [
  CoreFacade,
]
