<ng-container *ngIf="tours$ | async as tours">
  <div class="firm-tours" [ngClass]="{'vertical-view': isVertical}" *ngIf="tours.value?.length">
    <h2 *ngIf="title">{{ title }}</h2>
    <div class="row">
      <app-tour-item *ngFor="let tour of tours.value"
                     [tour]="tour"
                     [viewType]="viewType"
                     [isVertical]="isVertical"
                     [ngClass]="{'col-md-6 col-lg-4': !isVertical}"
                     class="col-12"
      ></app-tour-item>
    </div>
    <div *ngIf="moreLinkQueryParams" class="mt-20 mb-40 text-center more-block">
      <a class="link-more-block" [routerLink]="['/tours']" [queryParams]="moreLinkQueryParams">Смотреть больше туров</a>
    </div>
  </div>
</ng-container>
