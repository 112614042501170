import { FilesService, TagsService, UsersService, StaticDataService, RouterService } from 'wt-front-shared';

// import { StaticDataService } from '@app/core/lib/services/model-services/-static-data/static-data.service';

export const services = [
  UsersService,
  RouterService,
  FilesService,
  TagsService,

  StaticDataService
];

