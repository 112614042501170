import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderBannerComponent } from '@app/core/lib/components/header-banner/header-banner.component';

@NgModule({
  declarations: [HeaderBannerComponent],
  imports: [
    CommonModule
  ],
  exports: [HeaderBannerComponent],
})
export class HeaderBannerModule { }
