import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { StaticDataFacade, StaticDataModel } from 'wt-front-shared';
import { Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";

import { AppConfig } from "@app/core/lib/config/app-config";

@Component({
  selector: 'app-tours',
  templateUrl: './tours.component.html',
  styleUrls: ['./tours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToursComponent implements OnInit {
  @Input() title: string;
  @Input() moreLinkQueryParams: { [key: string]: string | number };
  @Input() firmId: number;
  @Input() countries: number[];
  @Input() tourCategoryIds: number[];
  @Input() cityId: number;
  @Input() perPage: number = 12;
  @Input() orderBy: string;
  @Input() isVertical = false;
  @Input() viewType: 'normal' | 'small' = 'normal';

  tours$: Observable<{ value: StaticDataModel[] }>;

  constructor(private staticDataFacade: StaticDataFacade) {
  }

  ngOnInit(): void {
    this.loadTours();
  }

  private loadTours(): void {
    let filterOptions = {};
    let loadFilterOptions = {};

    if (this.firmId || this.countries || this.tourCategoryIds) {
      if (this.firmId) {
        filterOptions = {
          firmId: this.firmId
        };
        loadFilterOptions = {
          firmId: this.firmId
        }
      }

      if (this.countries && this.countries[0]) {
        filterOptions = {
          ...filterOptions,
          countries: `contains(${this.countries})`
        };
        loadFilterOptions = {
          ...loadFilterOptions,
          countries: this.countries
        }
      }

      if (this.tourCategoryIds && this.tourCategoryIds[0]) {
        filterOptions = {
          ...filterOptions,
          categories: `contains(${this.tourCategoryIds})`
        };
        loadFilterOptions = {
          ...loadFilterOptions,
          categories: this.tourCategoryIds
        }
      }
    }

    this.staticDataFacade.loadStaticData(AppConfig.staticData.tours.key, {
      where: filterOptions,
      paging: {perPage: this.perPage},
      sort: this.orderBy
    });
    this.tours$ = this.staticDataFacade.getStaticData$(AppConfig.staticData.tours.key, 'list', null, loadFilterOptions)
      .pipe(
        filter(items => !!items && !!items.length),
        take(1),
        map(item => ({value: item || null}))
      );
  }
}
