import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { FrontTemplateComponent } from '@app/front-app/src/lib/containers/front-template/front-template.component';
import { PageNotFoundComponent } from "@app/front-app/src/lib/components/page-not-found/page-not-found.component";
import { RevisionsResolver } from "@app/front-app/src/lib/resolvers/revisions.resolver";

export const routes: Route[] = [
  {
    path: '',
    component: FrontTemplateComponent,
    resolve: [RevisionsResolver],
    children: [
      {
        path: '',
        loadChildren: () => import('@app/front-main/front-main.module').then(m => m.FrontMainModule),
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        title: 'Туристический портал World-Travel uz'
      },
      {
        path: 'destinations',
        loadChildren: () => import('./destinations/destinations.module').then(m => m.DestinationsModule),
      },
      {
        path: 'travel-agency',
        loadChildren: () => import('@app/front-firms/front-firms.module').then(m => m.FrontFirmsModule),
        runGuardsAndResolvers: 'paramsOrQueryParamsChange'
      },
      {
        path: 'tours',
        loadChildren: () => import('@app/front-tours/front-tours.module').then(m => m.FrontToursModule),
        runGuardsAndResolvers: 'paramsOrQueryParamsChange'
      },
      {
        path: 'tourist-info',
        loadChildren: () => import('./blog/front-blog.module').then(m => m.FrontBlogModule),
        runGuardsAndResolvers: 'paramsOrQueryParamsChange'
      },
      {
        path: 'pages',
        loadChildren: () => import('@app/front-pages/front-pages.module').then(m => m.FrontPagesModule),
        runGuardsAndResolvers: 'paramsOrQueryParamsChange'
      },
      {
        path: 'tags',
        loadChildren: () => import('@app/front-tags/front-tags.module').then(m => m.FrontTagsModule),
        runGuardsAndResolvers: 'paramsOrQueryParamsChange'
      },
      {
        path: 'links',
        loadChildren: () => import('@app/links/links.module').then(m => m.LinksModule),
        runGuardsAndResolvers: 'paramsOrQueryParamsChange'
      },
      {
        path: 'touristInfo',
        redirectTo: 'tourist-info'
      },
      {
        path: 'touristinfo',
        redirectTo: 'tourist-info'
      },
      {
        path: 'resorts',
        redirectTo: 'tours'
      },
      {
        path: 'adsUsers/description/:slug',
        pathMatch: 'full',
        redirectTo: 'tours'
      },
      {
        path: 'resorts/description',
        pathMatch: 'full',
        redirectTo: 'tours',
      },
      {
        path: 'resorts/category',
        pathMatch: 'full',
        redirectTo: 'tours',
      },
      {
        path: 'resorts/description/:slug2',
        pathMatch: 'full',
        redirectTo: 'tours',
      },
      {
        path: 'resorts/category/:slug2',
        pathMatch: 'full',
        redirectTo: 'tours',
      },
      {
        path: 'hotels',
        pathMatch: 'full',
        redirectTo: 'tours',
      },
      {
        path: 'hotels/country/:name',
        pathMatch: 'full',
        redirectTo: 'tours',
      },
      {
        path: 'travelAgency',
        pathMatch: 'full',
        redirectTo: 'travel-agency',
      },
      {
        path: 'travelAgency/description',
        pathMatch: 'full',
        redirectTo: 'travel-agency',
      },
      {
        path: 'travelAgency/description/:slug',
        pathMatch: 'full',
        redirectTo: 'travel-agency',
      },
      {
        path: 'country',
        pathMatch: 'full',
        redirectTo: 'tours',
      },
      {
        path: 'country/description',
        pathMatch: 'full',
        redirectTo: 'tours',
      },
      {
        path: 'country/description/:slug',
        pathMatch: 'full',
        redirectTo: 'tours',
      },
      {
        path: 'Country/description/:slug',
        pathMatch: 'full',
        redirectTo: 'tours',
      },
    ]
  },
  {
    path: '**',
    component: PageNotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [RevisionsResolver]
})
export class FrontAppRoutingModule {
}
