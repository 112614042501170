import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { StaticDataFacade, StaticDataModel, untilComponentDestroyed } from 'wt-front-shared';
import { AppConfig } from '@app/core/lib/config/app-config';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopBannerComponent implements OnInit, OnDestroy {
  banners: StaticDataModel[];
  selectedBanner: StaticDataModel;

  constructor(private staticDataFacade: StaticDataFacade,
              private router: Router,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.loadBanners();
    this.subscribeToRouteChange();
  }

  ngOnDestroy() {
  }

  navigation(url: string): void {
    const WTBaseUrl = 'https://world-travel.uz/';
    let newUrl = url;
    if (url.indexOf('http://wor;d-travel.uz') !== -1) {
      newUrl = url.replace(WTBaseUrl, '');
      this.router.navigate([newUrl])
    } else {
      window.location.href = url;
    }
  }

  private getBanner(url: string): void {
    let filteredBanners;
    if (this.banners?.length) {
      filteredBanners = this.banners.filter(item => url === item.sectionUrl);

      if (!filteredBanners.length) {
        filteredBanners = this.banners.filter(item =>
          url.indexOf(item.sectionUrl)
        );
      }

      const forAllPagesFilteredBanners = this.banners.filter(item => item.forAllPages);
      filteredBanners = [...filteredBanners, ...forAllPagesFilteredBanners];

      if (filteredBanners?.length === 1) {
        this.selectedBanner = filteredBanners[0];
      } else {
        const randomNumber = Math.floor(Math.random() * filteredBanners?.length);
        this.selectedBanner = filteredBanners[randomNumber];
      }
    }

    if (this.selectedBanner) {
      this.cd.markForCheck();
    }
  }

  private subscribeToRouteChange(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        untilComponentDestroyed(this)
      )
      .subscribe((event: any) => {
        this.getBanner(event.url);
      });
  }

  private loadBanners(): void {
    const params = {
      paging: { perPage: -1 }
    };
    this.staticDataFacade.loadStaticData(AppConfig.staticData.banners.key, params);

    this.staticDataFacade.getStaticData$(AppConfig.staticData.banners.key)
      .pipe(
        filter(items => !!items?.length),
        take(1),
        untilComponentDestroyed(this)
      )
      .subscribe(data => {
        this.banners = data;
        this.getBanner(this.router.url);
      });
  }
}
