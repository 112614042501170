import { createFeatureSelector, createSelector } from '@ngrx/store';

interface ListAndIds {
  list: any[];
  ids: any[]
}

const getStore = (storeKey: string) => {
  let rootStore = storeKey;
  let nestedStore = '';
  if (storeKey.includes('.')) {
    const params = storeKey.split('.');
    rootStore = params[0];
    nestedStore = params[1] !== 'list' ? params[1] : params[2];
  }

  const getCoreState = createFeatureSelector<any>(rootStore);

  return createSelector(
    getCoreState,
    (state: ListAndIds | {[key: string]: ListAndIds}) => {
      if (state.list) {
        if (nestedStore) {
          return state.list[nestedStore];
        }

        return state.list;
      }
      return state;
    }
  );
}

export const coreQuery = {
  getStore
};
