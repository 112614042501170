import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { StaticDataModel  } from 'wt-front-shared';

@Component({
  selector: 'app-tour-item',
  templateUrl: './tour-item.component.html',
  styleUrls: ['./tour-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TourItemComponent implements OnInit {
  @Input() tour: StaticDataModel;
  @Input() viewType: 'normal' | 'small' = 'normal';
  @Input() isVertical: boolean;

  ArrayObject = Array;
  constructor() { }

  ngOnInit(): void {
  }
}
