<!-- header-start -->
<header>
  <div class="header-area ">
    <div id="sticky-header" class="main-header-area">
      <div class="container-fluid">
        <div class="header_bottom_border">
          <div class="row align-items-center">
            <div class="col-10 col-xl-2 col-lg-2">
              <div class="logo">
                <a [routerLink]="['/']" class="color-dark">
                  <img src="assets/img/logo.png" alt="">
                  <span *ngIf="configRegion === 'uz'">World-Travel.uz</span>
                  <span *ngIf="configRegion === 'kz'">World-Travel.kz</span>
                </a>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 d-none d-lg-block">
              <div class="main-menu">
                <nav>
                  <ul id="navigation">
                    <li *ngFor="let item of menuItems; let num = index;">
                      <a [ngClass]="{'active': num === 0 }" [routerLink]="[item.link]">{{item.title}}</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 d-none d-lg-block">
              <div class="social_wrap d-flex align-items-center justify-content-end">
                <!--<div class="number">
                  <p><i class="fa fa-phone"></i> +9 (9891) 019-35-52</p>
                </div>-->
                <div class="top-button">
                  <shared-button
                    class="mr-8"
                    type="button"
                    color="accent"
                    styleTheme="raised"
                    (click)="submitClick()"
                  >Войти
                  </shared-button>
                  <shared-button
                    class="mr-8"
                    type="button"
                    color="warn"
                    styleTheme="raised"
                    (click)="submitClick(true)"
                  >Зарегистрироваться
                  </shared-button>
                </div>
                <!--<div class="social_links d-none d-xl-block">
                  <ul>
                    <li><a href="#"> <i class="fa fa-instagram"></i> </a></li>
                    <li><a href="#"> <i class="fa fa-linkedin"></i> </a></li>
                    <li><a href="#"> <i class="fa fa-facebook"></i> </a></li>
                    <li><a href="#"> <i class="fa fa-google-plus"></i> </a></li>
                  </ul>
                </div>-->
              </div>
            </div>
            <div class="col-2 d-block d-lg-none">
              <i (click)="toggleMenu(menu)" class="mob-menu-icon fa fa-bars"></i>
            </div>
          </div>
          <div class="row d-lg-none">
            <div class="col-12 mob-main-menu close-menu" #menu>
              <nav>
                <ul class="navigation">
                  <li *ngFor="let item of menuItems; let num = index;">
                    <a [ngClass]="{'active': num === 0 }" [routerLink]="[item.link]">{{item.title}}</a>
                  </li>
                  <li>
                    <shared-button
                      class="mr-8"
                      type="button"
                      color="accent"
                      styleTheme="raised"
                      (click)="submitClick()"
                    >Войти
                    </shared-button>
                  </li>
                  <li>
                    <shared-button
                      class="mr-8"
                      type="button"
                      color="warn"
                      styleTheme="raised"
                      (click)="submitClick(true)"
                    >Зарегистрироваться
                    </shared-button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="header-sub-line">
      <div class="field">
        <p *ngIf="configRegion === 'uz'"><i class="fa fa-phone"></i> +9 (9888) 944-00-33</p>
      </div>
      <div class="field">
        <p><a href="https://t.me/uzWorldTravel" target="_blank" title="Наш телеграм канал">
          <img src="assets/img/telegram.png" class="tg-ico" alt=""> @uzWorldTravel</a>
        </p>
      </div>
    </div>
  </div>
</header>
<!-- header-end -->

<app-top-banner></app-top-banner>
<router-outlet></router-outlet>

<footer class="footer">
  <div class="copy-right_text">
    <div class="container">
      <div class="footer_border"></div>
      <div class="row">
        <div class="col-xl-12">
          <p class="copy_right text-center">
            Copyright &copy;2016 All rights reserved<br/>
            Сервис не несет ответственность за точность информации в турах и услугах, предоставленных тур операторами.
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>

<!-- Modal -->
<!--<div class="modal fade custom_search_pop" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="serch_form">
        <input type="text" placeholder="Search" >
        <button type="submit">search</button>
      </div>
    </div>
  </div>
</div>-->
