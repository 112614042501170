import { IAppConfig, IAppConfigStaticData } from 'wt-front-shared';

const StaticData: IAppConfigStaticData = {
  countries: {
    key: 'countries',
    store: 'staticData.list.countries'
  },
  cities: {
    key: 'cities',
    store: 'staticData.list.cities'
  },
  toursCategories: {
    key: 'tours-categories',
    store: 'staticData.list.tours-categories'
  },
  toursAmenities: {
    key: 'tours-amenities',
    store: 'staticData.list.tours-amenities'
  },
  toursBeachTypes: {
    key: 'tours-beach-types',
    store: 'staticData.list.tours-beach-types'
  },
  toursFoodTypes: {
    key: 'tours-food-types',
    store: 'staticData.list.tours-food-types'
  },
  toursWaterEntertainment: {
    key: 'tours-water-entertainment',
    store: 'staticData.list.tours-water-entertainment'
  },
  firmsCategories: {
    key: 'firms-categories',
    store: 'staticData.list.firms-categories'
  },
  accommodationTypes: {
    key: 'accommodation-types',
    store: 'staticData.list.accommodation-types'
  },
  infos: {
    key: 'infos',
    store: 'staticData.list.infos'
  },
  subRouteInfosCountries: {
    key: 'infos-getInfosCountries',
    store: 'staticData.list.infos-getInfosCountries'
  },
  infosCountries: {
    key: 'infos-countries',
    store: 'staticData.list.infos-countries'
  },
  infosCategories: {
    key: 'infos-categories',
    store: 'staticData.list.infos-categories'
  },
  links: {
    key: 'links',
    store: 'staticData.list.links'
  },
  rightsBlocks: {
    key: 'rights-blocks',
    store: 'staticData.list.rights-blocks'
  },
  rolesRights: {
    key: 'roles-rights',
    store: 'staticData.list.roles-rights'
  },
  usersRoles: {
    key: 'users-roles',
    store: 'staticData.list.users-roles'
  },
  pages: {
    key: 'pages',
    store: 'staticData.list.pages'
  },
  firms: {
    key: 'firms',
    store: 'staticData.list.firms'
  },
  tours: {
    key: 'tours',
    store: 'staticData.list.tours'
  },
  subRouteToursGetToursByCountries: {
    key: 'tours-getToursByCountries',
    store: 'staticData.list.tours-getToursByCountries'
  },
  subRouteToursCountries: {
    key: 'tours-getToursCountries',
    store: 'staticData.list.tours-getToursCountries'
  },
  subRouteToursCategories: {
    key: 'tours-getToursCategories',
    store: 'staticData.list.tours-getToursCategories'
  },
  mailingCampaigns: {
    key: 'mailing-campaigns',
    store: 'staticData.list.mailing-campaigns'
  },
  mailingCampaignsLogs: {
    key: 'mailing-campaigns-logs',
    store: 'staticData.list.mailing-campaigns-logs'
  },
  destinations: {
    key: 'destinations',
    store: 'staticData.list.destinations'
  },
  destinationsTypes: {
    key: 'destinations-types',
    store: 'staticData.list.destinations-types'
  },
  subscribers: {
    key: 'subscribers',
    store: 'staticData.list.subscribers'
  },
  supportRooms: {
    key: 'support-rooms',
    store: 'staticData.list.support-rooms'
  },
  supportMessages: {
    key: 'support-messages',
    store: 'staticData.list.support-messages'
  },
  siteTours: {
    key: 'site-tours',
    store: 'staticData.list.site-tours'
  },
  siteToursSteps: {
    key: 'site-tours-steps',
    store: 'staticData.list.site-tours-steps'
  },
  banners: {
    key: 'banners',
    store: 'staticData.list.banners'
  }
};

export const AppConfig: IAppConfig = {
  staticData: StaticData,
}
