<div class="single_place" [ngClass]="{'small-single_place': viewType === 'small', 'vertical-single_place': isVertical}">
  <div class="thumb" [ngClass]="{'small-image-block': viewType === 'small'}">
    <a [routerLink]="['/tours', 'description', tour.slug + '.html']">
      <shared-image [versions]="tour.image?.versions" [version]="2"></shared-image>
    </a>
    <a [routerLink]="['/tours', 'description', tour.slug + '.html']" class="prise">от ${{ tour.price }}
    </a>
  </div>
  <div class="place_info">
    <a [routerLink]="['/tours', 'description', tour.slug + '.html']"><h3 class="tour-title">{{ tour.name }}</h3></a>
    <div class="rating_days d-flex justify-content-between">
          <span *ngIf="tour.hotelRating" class="d-flex justify-content-center align-items-center">
               <i *ngFor="let index of ArrayObject(tour.hotelRating)" class="fa fa-star"></i>
          </span>
    </div>
    <div class="tour-categories" *ngIf="tour.categories?.length">
      <ng-container
        *ngFor="let item of tour.categories; let num = index;">{{ num !== 0 ? ', ' : '' }}{{ item.name }}
      </ng-container>
    </div>
    <div class="tour-countries" *ngIf="tour.countriesNames?.length">
      <ng-container
        *ngFor="let item of tour.countriesNames; let num = index;">{{ num !== 0 ? ', ' : '' }}{{ item.name }}
      </ng-container>
    </div>

    <div class="tour-params">
      Взрослых: <b>{{ tour.howManyAdults }}</b>
      <ng-container *ngIf="tour.howManyChildren"> / Детей: <b>{{ tour.howManyChildren }}</b></ng-container>
      /
      Дней: <b>{{ tour.numberOfDays }}</b> <ng-container *ngIf="tour.numberOfNights"> / Ночей: <b>{{ tour.numberOfNights }}</b></ng-container>
    </div>
  </div>
</div>
