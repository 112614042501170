import { ChangeDetectionStrategy, Component, Inject, Optional, PLATFORM_ID } from '@angular/core';
import { RESPONSE } from "@nguniversal/express-engine/tokens";
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageNotFoundComponent {

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              @Optional() @Inject(RESPONSE) private response: any) {
  }

  ngOnInit() {
    if (!isPlatformBrowser(this.platformId)) {
      this.response.statusCode = 404;
      this.response.statusMessage = '404 - Page Not Found';
    }
  }
}
