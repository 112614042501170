import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import {
  AppConfig,
  CORE_CONFIG,
  StaticDataMetaStatus,
  untilComponentDestroyed,
  WebsocketMessageSubTypes,
  WebsocketMessageTypes,
  WebsocketService
} from 'wt-front-shared';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-front-template',
  templateUrl: './front-template.component.html',
  styleUrls: ['./front-template.component.scss']
})
export class FrontTemplateComponent implements OnInit, OnDestroy {
  isBrowser: boolean;
  configRegion;
  menuItems: { link: string, title: string }[] = [
    {link: '/', title: 'Главная'},
    {link: '/destinations', title: 'Направления'},
    {link: '/tours', title: 'Туры'},
    {link: '/travel-agency', title: 'Тур. фирмы'},
    {link: '/tourist-info', title: 'О туризме'},
    {link: '/pages/about', title: 'О проекте'},
  ]

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private websocketService: WebsocketService,
              @Inject(CORE_CONFIG) private config: AppConfig,
              @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.websocketService.wsSentMessage(WebsocketMessageTypes.USER_ACTIVITY, WebsocketMessageSubTypes.CHANGE_PAGE, this.activatedRoute.snapshot['_routerState'].url);

    this.configRegion = this.config.region;
    this.router.events
      .pipe(
        untilComponentDestroyed(this)
      )
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          console.info('[ navigation ][ change-url ]', event.url);
          this.websocketService.wsSentMessage(WebsocketMessageTypes.USER_ACTIVITY, WebsocketMessageSubTypes.CHANGE_PAGE, event.url);

          if (this.isBrowser) {
            window.scroll({
              top: 0,
              left: 0
            });
          }
        }
      });
  }

  ngOnDestroy() {
  }

  toggleMenu(menu: HTMLElement): void {
    menu.classList.toggle("close-menu");
  }

  submitClick(isRegistration = false): void {
    window.location.href = !isRegistration ? 'https://world-travel.uz:4200/' : 'https://world-travel.uz:4200/auth/registration';
  }

  protected readonly StaticDataMetaStatus = StaticDataMetaStatus;
}
