<section class="error-404-section section-padding">
  <div>
    <div class="error">
      <img src="assets/img/error404.png" />
    </div>
    <div class="error-message">
      <h3>Ой! Страница не найдена!</h3>
      <p>К сожалению мы не можем найти запрошенную вами страницу.<br />Возможно, вы неправильно ввели адрес.</p>
      <a href="https://world-travel.uz" class="theme-btn">На главную</a>
    </div>
  </div>
  <div class="popular_places_area inner_page tour-details">
    <div class="container">
      <div class="mt-60">
        <h2 class="empty-page_sub-title">Но вы можете посмотреть наши последние туры</h2>
        <app-tours [perPage]="9" [orderBy]="'-id'"></app-tours>
      </div>
    </div>
  </div>
</section>
