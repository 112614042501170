import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { ImageModule, PipesModule, WtFrontSharedModule } from 'wt-front-shared';

import { FrontCloudTagsComponent } from "@app/front-app/src/lib/components/front-cloud-tags/front-cloud-tags.component";
import {
  FrontRecentPostComponent
} from "@app/front-app/src/lib/components/front-recent-post/front-recent-post.component";
import { FrontBlogSearchComponent } from "@app/front-blog/components/front-blog-search/front-blog-search.component";
import { BlogItemsComponent } from "@app/front-app/src/lib/components/blog-items/blog-items.component";
import { TourItemComponent } from "@app/front-app/src/lib/components/tour-item/tour-item.component";
import { ToursComponent } from "@app/front-app/src/lib/components/tours/tours.component";
import {
  FrontBlogOthersToursComponent
} from '@app/front-app/src/lib/components/front-blog-others-tours/front-blog-others-tours.component';
import { TopBannerComponent } from '@app/front-app/src/lib/components/top-banner/top-banner.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ImageModule,
    PipesModule,

    WtFrontSharedModule
  ],
  declarations: [
    FrontCloudTagsComponent,
    FrontRecentPostComponent,
    FrontBlogSearchComponent,
    BlogItemsComponent,
    TourItemComponent,
    ToursComponent,
    FrontBlogOthersToursComponent,
    TopBannerComponent
  ],
  exports: [
    FrontCloudTagsComponent,
    FrontRecentPostComponent,
    FrontBlogSearchComponent,
    BlogItemsComponent,
    TourItemComponent,
    ToursComponent,
    CommonModule,
    FrontBlogOthersToursComponent,
    TopBannerComponent
  ],
})
export class FrontAppSharedModule {
}
