import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { AppState } from '@app/state/core.reducer';
import { StaticDataFacade } from 'wt-front-shared';
import { filter, map, take } from "rxjs/operators";

@Injectable()
export class RevisionsResolver implements Resolve<Observable<boolean>> {

  constructor(private store: Store<AppState>,
              private staticDataFacade: StaticDataFacade,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.getFromStoreOrAPI();
  }

  getFromStoreOrAPI(): Observable<boolean> {
    this.staticDataFacade.loadStaticDataRevisions();
    return this.staticDataFacade.getStaticDataMeta$('revisions')
      .pipe(
        filter(item => !!item),
        filter(item => item.status === 'loaded'),
        take(1),
        map(item => {
          return true;
        })
      );
    // return of(true)
  }
}
